import { CompatClient, IMessage, Stomp, StompHeaders, messageCallbackType } from "@stomp/stompjs";
import axios from "axios";
import SockJS from "sockjs-client";
import Vue, { ref } from 'vue';
export type SocketSubscribeOutput = {
	body: string 
}

export type ProgressInfo = {
	data: {
		processed: number
		total: number
	},
	percent: number
}

export type SocketSubscribeOutputBody = {
	resultAsPath: string
	progressInfo: ProgressInfo,
	resultAsExcel: string
}

export interface SocketServiceImpl {
	connect(successCallback?: () => void, errorCallback?: () => void, headers?: StompHeaders): void
	subscribe(destination: string, callback: (message: IMessage) => void, headers?: StompHeaders): void
	unsubscribe(destination: string, headers?: StompHeaders): void
	send(destination: string, body: string, headers: StompHeaders): void
	disconnect(): void
}

export default {
	async install(app: Vue.App) {
		class SocketService implements SocketServiceImpl {
			private stompClient: CompatClient
			public connected: boolean = false 
		
			constructor(stompClient: CompatClient) {
				stompClient.reconnect_delay = 5000
				this.stompClient = stompClient
			}

			connect(successCallback?: () => void, errorCallback?: () => void, headers: StompHeaders = {}): Promise<void> {
				return new Promise<void>(async (resolve) => {
					if (!this.connected) {
						stompClient.connect(headers, () => {
							this.connected = true;
							resolve();
							if (successCallback) {
									successCallback();
							}
						}, () => {
							this.connected = false;
							if (errorCallback) {
									errorCallback();
							}
							resolve();
						});
					} else {
						if (successCallback) {
							successCallback();
						}
						resolve();
					}
				});
		}

			subscribe(destination: string, callback: (message: IMessage) => void, headers?: StompHeaders) {
				this.stompClient.subscribe(destination, (message: IMessage) => {
					callback(message)
				}, headers)
			}

			unsubscribe(destination: string, headers?: StompHeaders) {
				this.stompClient.unsubscribe(destination, headers)
			}

			send(destination: string, body: string, headers?: StompHeaders) {
				this.stompClient.send(destination, headers, body)
			}

			disconnect() {
				this.stompClient.disconnect()
			}
		}

		const socket = new SockJS(`${axios.defaults.baseURL}/websocket?jwt=Bearer ${localStorage.getItem('eventAppToken')}`)
		const stompClient = Stomp.over(socket)

		const socketService = new SocketService(stompClient)
		app.config.globalProperties.$socketService = socketService
		await socketService.connect()
	}
}